<template>
    <div class="card card-body">
        <h1>Additional Parts</h1>

        <!-- Footer -->
        <fixed-footer
                :class="{'active': true}"
                :nextLabel="`Next`"
                :nextRoute="`revisit.note`"
                :prevRoute="`revisit.repair-parts`">
        </fixed-footer>
    </div>
</template>

<script>
    export default {
        name: "AdditionalParts"
    }
</script>

<style scoped>

</style>